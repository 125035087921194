import { $ajaxService } from './request'
import { service } from './request'

/**
 * 任务列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} member_userid 成员id
 * @param  {} customer_userid 客户userid
 * @param  {} page
 * @param  {} page_size
 */
export const getMarketingTaskList = ({
  member_userid,
  customer_userid,
  chat_id,
  page,
  page_size
}) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/sendor_task_list', {
    member_userid,
    customer_userid,
    chat_id,
    page,
    page_size
  })

/**
 * 任务详情 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} record_id 任务id
 */
export const getMarketingTaskDetail = ({ record_id, task_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/sendor_task_detail', {
    record_id,
    task_id
  })

/**
 * 记录被编辑过的任务 https://paas-teamwork.yuque.com/oundy7/vrp0sx/qrc1ag#uEOME
 * @param  {} task_id 任务id
 * @param  {} content 编辑的内容文字
 * @param  {} annex_content_json string	内容的资源
 */
export const recordEditTask = ({ task_id, content, annex_content_json }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/send/edit', {
    task_id,
    content,
    annex_content_json
  })

/**
 * 获取待执行任务总数 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} member_userid 成员id
 * @param  {} customer_userid 客户userid
 */
export const getTaskcount = ({ member_userid, customer_userid, chat_id }) => {
  // console.log('掉借口前打印', customer_userid)
  return $ajaxService.post($ajaxService.baseUrl + 'task/sendor_task_count', {
    member_userid,
    customer_userid,
    chat_id
  })
}
/**
 * 成员转发信息之后回传状态 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} send_record_son_id 子任务id
 * @param  {} member_userid 成员userid
 * @param  {} external_userid 客户（外部）userid
 * @param  {} send_status 发送状态
 */
export const backTaskStatus = ({
  send_record_son_id,
  member_userid,
  external_userid,
  send_status
}) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/sendor_execute_new', {
    send_record_son_id,
    member_userid,
    external_userid,
    send_status
  })

//  ==========================知识库部分=================================================
/**
 * 知识库分组列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/rpuv8f#GoeTd
 * @param  {} type 分组查询类型：0=知识库分组查询  ； 1=推荐回复分组查询（返回关键词数量）  ；不传递默认为0
 * @param  {} get_sale_script_num 是否获取分组下的知识数量 ：0=不获取；1=获取；不传递默认为0
 * @param  {} search_content string	筛选知识内容
 * @param  {} search_group_name 筛选知识分组名称
 */
export const getGroupList = ({
  type,
  get_sale_script_num,
  search_content,
  search_group_name
}) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_group/get_group_list',
    {
      type,
      get_sale_script_num,
      search_content,
      search_group_name
    }
  )

/**
 * 获取知识库话术列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/rpuv8f#GoeTd
 * @param  {} group_id 分组id
 * @param  {} search_content 筛选文本内容
 */
export const getKnowledgeList = ({ group_id, search_content }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script/get_sale_script_list',
    {
      group_id,
      search_content
    }
  )

/**
 * 获取知识库话术详情 https://paas-teamwork.yuque.com/oundy7/vrp0sx/rpuv8f#GoeTd
 * @param  {} sale_script_id 话术id
 */
export const getKnowledgeDetail = ({ sale_script_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script/get_sale_script_detail',
    {
      sale_script_id
    }
  )

/**
 * 话术发送统计(个人话术) https://paas-teamwork.yuque.com/oundy7/vrp0sx/rpuv8f#GoeTd
 * @param  {} sale_script_id 话术id
 */
export const backPersonalSend = ({ sale_script_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script/add_sale_script_send_num',
    {
      sale_script_id
    }
  )
// ==============================================================推荐回复模块==========================================================================================
/**
 * 获取推荐回复会话列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/abdx1v#KJ6Dg
 * @param  {} chat_id 群聊id （chat_id 和customer_id 二选一填一个）
 * @param  {} customer_id 客户id
 */
export const getRecommandList = ({ chat_id, customer_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script/get_recommend_reply_list',
    {
      chat_id,
      customer_id
    }
  )

/**
 * 获取推荐回复匹配的话术列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/abdx1v#TkK8U
 * @param  {} work_message_id 推荐回复消息id
 */
export const getRecommandSpeechList = ({ work_message_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script/get_recommend_sale_script_list',
    {
      work_message_id
    }
  )

// ========================================================个人（我的）话术================================================
/**
 * 获取个人话术列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lf2fcg#VrQB0
 * @param  {} page_num 分页页码
 * @param  {} page_size 分页大小
 * @param  {} search_content 搜索内容
 */
export const getSpeechList = ({ page_num, page_size, search_content }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_personal/get_sale_script_list',
    {
      page_num,
      page_size,
      search_content
    }
  )

/**
 * 添加个人话术 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lf2fcg#VgUov
 * @param  {} content 话术内容
 */
export const addPersonalSpeech = ({ content }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_personal/add_sale_script',
    {
      content
    }
  )

/**
 * 编辑个人话术 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lf2fcg#VgUov
 * @param  {} sale_script_id 话术ID
 * @param  {} content 话术内容
 */
export const editPersonalSpeech = ({ sale_script_id, content }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_personal/update_sale_script',
    {
      sale_script_id,
      content
    }
  )

/**
 * 个人话术拖拽排序 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lf2fcg#p8ymV
 * @param  {} sale_script_list 新的话术分组排序
 * @param  {} sale_script_list.sale_script_id 话术ID
 * @param  {} sale_script_list.sort 新的话术排序，正序 1,2,3,4...
 */
export const changeSpeechsSort = ({ sale_script_list }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_personal/update_sale_script_sort',
    {
      sale_script_list
    }
  )

/**
 * 删除个人话术 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lf2fcg#VgUov
 * @param  {} sale_script_id 话术ID
 */
export const deletePersonalSpeech = ({ sale_script_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_personal/delete_sale_script',
    {
      sale_script_id
    }
  )

// =====================================================标签营销============================================
/**
 * 获取推荐的标签营销列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lyoogp#KJ6Dg
 * @param  {} external_userid 企微外部联系人id
 */
export const getMaketingLabelList = ({ external_userid }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_tag/get_tag_list',
    {
      external_userid
    }
  )

/**
 * 获取标签营销匹配的话术列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lyoogp#TkK8U
 * @param  {} tag_id_arr 标签id
 */
export const getLabelSpeechList = ({ tag_id_arr }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_tag/get_tag_sale_script_list',
    {
      tag_id_arr
    }
  )

/**
 * 获取标签营销详情 https://paas-teamwork.yuque.com/oundy7/vrp0sx/lyoogp#TkK8U
 * @param  {} sale_script_id 会话id
 */
export const getMarketingLabelDetail = ({ sale_script_id }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'guide/sale_script_tag/get_sale_script_detail',
    {
      sale_script_id
    }
  )

/**
 * 记录导购端知识/个人话术动态 https://paas-teamwork.yuque.com/oundy7/vrp0sx/fsuxp5#MlZK6
 * @param  {} sale_script_id 会话id
 * @param  {} event_key 类型
 * @param  {} field 客户id或者群id
 * @param  {} field_value 取值为 群id/个人客户id
 */
export const setBackSend = ({
  sale_script_id,
  event_key,
  field,
  field_value,
  tag_idx
}) =>
  $ajaxService.post($ajaxService.baseUrl + 'sale_script/events_put', {
    sale_script_id,
    event_key,
    field,
    field_value,
    tag_idx
  })
