// 格式化任务列表媒体数据
export function formatTaskItem(data = []) {
  if (!data || !data.length) return []
  const formatData = [...data]
  for (let i = 0; i < formatData.length; i++) {
    const {
      type,
      title,
      appid,
      url,
      upload_url,
      description,
      qw_link_title,
      media_url
    } = formatData[i]
    if (type === 'we_applets' || type === 'commodity' || type === 'coupon') {
      formatData[i].type = 'MINIPROGRAM'
      formatData[i].qw_miniprogram_title = title
      formatData[i].qw_miniprogram_appid = appid
      formatData[i].qw_miniprogram_page = url
      formatData[i].file_url = upload_url
    } else {
      switch (type) {
        case 'images':
          formatData[i].type = 'IMAGE'
          formatData[i].file_url = upload_url
          break
        case 'web_page':
          formatData[i].type = 'LINK'
          formatData[i].qw_link_title = title
          formatData[i].file_url = upload_url
          formatData[i].qw_link_desc = description
          formatData[i].qw_link_url = url
          break
        case 'video':
          formatData[i].type = 'VIDEO'
          formatData[i].title = qw_link_title
          formatData[i].file_url = media_url
          break
        case 'file':
          formatData[i].type = 'FILE'
          // formatData[i].title = formatData[i].qw_link_title
          formatData[i].file_url = media_url
          break
      }
    }
  }
  return formatData
}

// 判断wechat文件类型
export function formatType(medisType) {
  let type = ''
  switch (medisType) {
    case 'TEXT':
      type = 'text'
      break
    case 'IMAGE':
      type = 'image'
      break
    case 'LINK':
      type = 'news'
      break
    case 'VIDEO':
      type = 'video'
      break
    case 'MINIPROGRAM':
      type = 'miniprogram'
      break
    case 'FILE':
      type = 'news'
      break
    default:
      type = 'text'
      break
  }
  return Promise.resolve(type)
}
/**
 * 把params转换成URL参数
 * */
export const encodeParams = (params = {}) => {
  if (!params) return ''
  let result = []
  for (let key in params) {
    let value = params[key]
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue
    }
    result.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value))
    )
  }
  return result.length ? '?' + result.join('&') : ''
}
